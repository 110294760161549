@import '../utils/variables';

@mixin noBorder {
    height: 34px;
    background-color: $white;
    padding: $space4 $space12;
    transition: all .3s ease-in-out;
    outline: 0;
}

@mixin formItemGrid {
    flex: 1;

    &:not(:last-child) {
        margin-right: $space;
    }
}

// Reset Fields
input {
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: 0;
}

// Lightbox
.lightbox-wrap {
    .ant-modal-header {
        padding: $space;
    }

    .ant-modal-title {
        font-size: $fontSize20;
        font-weight: bold;
        position: relative;
    }

    .ant-modal-body {
        min-height: 150px;
        padding: $space30 $space40;
    }

    .title {
        font-size: 15px;
        font-weight: normal;
        color: $black;
        margin-bottom: $space4;
    }

    .items {
        display: flex;

        .row {
            @include formItemGrid;
        }
    }
}

// Form
.row {
    display: block;
    margin-bottom: $space;

    .field {
        font-size: 15px;
        border: 1px solid $border;
        border-radius: 2px;
        @include noBorder;

        &:hover,
        &:focus {
            border-color: $blue;
        }

        &:focus {
            box-shadow: 0 0 1px 2px rgba(33, 150, 243, .3);
        }

        > * {
            &:not(.ant-switch) {
                width: 100%;
            }
        }
    }

    &.disabled {
        .field {
            background-color: $border1;
        }
    }

    .disabled {
        &:hover,
        &:focus {
            border-color: $border;
        }

        [disabled] {
            background-color: $border1;
            cursor: not-allowed;

            &:hover {
                border-color: $border;
            }
        }
    }

    .ant-picker {
        width: 100%;
        height: 34px;

        &:focus,
        &:active {
            border: 1px solid $blue;
            box-shadow: none;
            outline: 0;
        }

        &.ant-picker-focused {
            box-shadow: none;

            .ant-picker-active-bar {
                display: none;
            }
        }

        .ant-picker-input {
            &:focus {
                outline: 0;
            }

            input {
                font-size: 15px;
                letter-spacing: 1px;
            }
        }
    }

    .noBorder {
        border: 0;
        padding: 0;

        select {
            @include noBorder;
            padding: $space4 $space8;
        }
    }

    .ant-input,
    select {
        height: 34px;
        font-size: 15px;
    }

    select {
        font-size: 15px;
        border-color: $border;
        border-radius: 2px;
        padding: $space4 $space12;
        transition: all .3s ease-in-out;

        &:hover {
            border-color: $blue;
        }
    }

    textarea {
        height: 100%;
        font-size: 15px;
        color: $black;
        border: 1px solid $border;
        border-radius: 2px;
        padding: $space8 $space12;
        resize: none;
        outline: 0;
        transition: all .3s ease-in-out;

        &:hover {
            border-color: $blue;
        }
    }

    &.textarea {
        .field {
            min-height: 60px;
        }
    }
}

.notes {
    font-size: 13px;
    color: $blue;
    margin-top: 6px;

    * {
        font-size: 14px;
    }

    .anticon {
        margin-right: 6px;
    }
}

.readOnly {
    .field {
        background-color: $grayLight;

        &:hover,
        &:focus {
            border-color: $border;
        }
    }
}

.form-radios {
    * {
        @include v-align;
    }
}

.row-btns {
    text-align: center;
    margin-top: $space;
    @include modalBtns;

    [class*="btn-"] {
        margin: 0 $space;
    }
}

// Title Required Star
.isRequired {
    &:before {
        content: '*';
        font-size: 13px;
        color: $red;
        margin-right: $space4;
        @include v-align;
    }
}

// 顯示錯誤
.hasError {
    .field {
        border-color: $red;

        &:hover {
            border-color: $red;
        }
    }

    .error {
        font-size: 15px;
        color: $red;
        margin: 2px 0;
    }

    .noBorder select {
        border-color: $red;
    }

    // 到職日
    .date-picker-input {
        border-color: $red;

        &:hover {
            border-color: $red;
        }
    }

    .required {
        font-size: 15px;
        color: $red;
        display: inline-block;
        margin-top: $space4;
    }
}

// Form - 分公司
.isBranch {
    display: inline-block;

    .field {
        border: 0;
        padding: 0;

        > span {
            margin-right: $space12;
        }
    }
}

// Form - 薪資
.form-salary {
    .salary-notes {
        .field {
            min-height: 80px;
        }
    }

    .ant-calendar-picker {
        width: 100%;
    }
}

// Form - 技能
.skillsWrap {
    margin: 10px 0 $space30;

    .checkboxWrap {
        $grid: 6;
        $getSpace: 10px;
        width: calc(100% / #{$grid} - #{$getSpace});
        margin-right: calc(#{$getSpace} * #{$grid} / (#{$grid} - 1));
        @include v-align;

        &:nth-child(6n + 6) {
            margin-right: 0;
        }
    }
}

// Form - 色碼
.colorWrap {
    .field {
        border: 0;
        padding: 0;
    }

    [type="color"] {
        height: 34px;
    }
}

// Form - 行事曆
.calendarLightbox {
    .form-radios .field {
        border: 0;
        padding: 0;

        > * {
            width: auto;
        }
    }
}

// Checkbox, Radio Button
.items-choose {
    margin-top: 10px;

    .cell {
        line-height: 1.8;
        flex: 1;

        &:not(:last-child) {
            margin-right: $space;
        }
    }

    h4 {
        $circle: 6px;
        border-bottom: 1px solid $border;
        position: relative;

        &:after {
            content: '';
            width: $circle;
            height: $circle;
            background-color: $border;
            border-radius: 50%;
            position: absolute;
            right: 0;
            bottom: -3px;
        }
    }
}

// Form - 專案
.contractWrap {
    display: inline-block;
    cursor: pointer;

    .checkboxWrap label {
        margin-right: $space4;
    }
}

// Form - 登錄工時
.workLogWrap {
    min-height: 200px;

    [class*="workLog"] {
        margin-bottom: 0;
    }

    .ant-radio-wrapper {
        display: block;
        margin-bottom: $space4;

        > * {
            @include v-align;
        }
    }

    .tagsWrap {
        min-height: 22px;
        font-size: 13px;
        margin-top: 0;
        margin-left: 6px;
        padding: 0 6px;
    }
}

// 彈性工時
.workLogHours {
    .field {
        [class*="label-"] {
            width: calc((100% / 2) - 6px);
            float: left;
            margin-right: $space12;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .label-hour {
        border: 1px solid $border;

        input {
            width: 100%;
        }
    }

    .warning-text {
        font-size: 13px;
        color: $blue;
    }

    .required {
        font-size: 15px;
        color: $red;
        display: inline-block;
        margin-top: $space4;
    }
}

.workLogReminder {
    margin-top: $space4;

    .reminder-item {
        font-size: 13px;
        color: $grayDark;

        * {
            font-size: $fontSize14;
        }

        .anticon {
            margin-right: $space4;
        }
    }
}

// 錯誤訊息: 客製化欄位
.other-error-mesg {
    margin-top: $space;

    * {
        color: $red;
    }

    .mesg {
        margin-left: $space8;
    }
}

// 員工
.is-extra-pm {
    color: #21ba45;
}
