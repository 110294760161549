@import '../utils/variables';

.checkboxLabel {
    .title {
        margin-bottom: $space4;
    }

    .event {
        font-size: $fontSize14;
        color: $blue;
        float: right;
        padding: 0 0 0 $space4;
        cursor: pointer;
    }

    .checkboxesContainer {
        max-height: 270px;
        overflow-y: auto;
    }

    .checkbox-item {
        line-height: 2;
        display: block;
        margin-left: 10px;
    }
}
