@import '../../utils/variables';

.personal-skill-row {
    margin-bottom: 6px;

    .tagsWrap {
        border: 1px solid $grayLight !important;
        margin-left: 0;
        padding: 2px 6px;
    }

    span {
        display: inline-block;
    }

    .score {
        font-size: $fontSize14;
        top: -2px;
        @include place;
    }
}

.personal-skill-tip {
    .ant-popover-inner-content {
        min-width: 315px;
        max-width: 315px;
    }

    > * {
        @include v-align;
    }

    .ant-progress-text {
        text-align: right;
        margin-left: $space4;
    }

    h2 {
        margin-bottom: 0;
    }

    .name {
        font-size: $fontSize18;
        font-weight: bold;
    }

    .btn-action,
    .username {
        font-size: $fontSize14;
        color: $blue;
        display: inline-block;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .link-actions {
        margin-bottom: $space16;
    }

    .btn-action {
        margin-right: 10px;
    }

    .username {
        margin-left: 10px;
    }

    p {
        line-height: 1;
        margin-bottom: $space8;
    }

    [class*="personal"] {
        margin-bottom: $space;

        > [class*="Wrap"] {
            padding-left: 10px;
        }
    }

    .label {
        @include v-align;
        font-size: $fontSize14;
        background-color: $gray;
        margin-right: 10px;
        margin-bottom: 6px;
        padding: 1px 6px;
    }
}
