@import '../../utils/variables';

.dashboardTaskWrap {
    border-bottom: 1px solid $grayLight;
    padding: 10px $space;

    &:hover {
        background-color: $tableHead;
    }

    .project-name {
        line-height: 1.4;
        font-size: 15px;
        margin-bottom: 0;
        @include flexEllipsisText(2);
    }

    .action {
        text-align: right;
    }
}

.item-task {
    > * {
        font-size: 15px;
    }

    .info {
        * {
            font-size: $fontSize14;
            color: $grayDark;
        }
    }

    .btn-primary {
        height: auto;
        margin-right: 0;
        padding: 2px $space16;

        &:hover,
        &:focus {
            margin-right: 0;
        }

        * {
            font-size: 15px;
        }
    }
}

.storageData {
    font-size: $fontSize14;
    color: $black;
    background-color: #EEE;
    border-radius: 2px;
    padding: 2px 6px;
    @include v-align;

    * {
        font-size: $fontSize14;
    }

    .tagsWrap {
        font-size: 13px;
        vertical-align: 0;
        padding: 0 6px;
    }
}
