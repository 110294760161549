@import '../../utils/variables';

.tab-pane-settings {
    display: flex;
    align-items: flex-start;

    .on {
        display: block;
    }
}

.settingMenu {
    min-width: 240px;
    margin-right: $space60;

    .menu-item {
        padding: $space8 $space;
        cursor: pointer;

        &:hover {
            background-color: $grayLight;
        }

        &.on {
            color: $white;
            background-color: $blue;
        }
    }
}

.settingContent {
    flex: 1;
}

.settingsContentWrap {
    display: none;
    // min-height: calc(100vh - 241px); // 扣除其他高度與間距

    .top {
        margin-bottom: $space;

        .title {
            font-size: 24px;
            font-weight: bold;
            margin-right: $space16;
        }

        span:not(.title) {
            font-size: 15px;
            color: $blue;
            text-decoration: underline;
            margin-right: 10px;
            cursor: pointer;
        }
    }
}

.settings-form-wrap {
    > [class*="settings--"] {
        display: flex;
        flex-wrap: wrap;
        padding-left: 10px;
    }

    .settings-item-wrap {
        flex: 0 0 calc(100% / 3 - 10px);
        margin-right: 10px;
        margin-bottom: $space8;

        .dot {
            $dot: 6px;
            width: $dot;
            height: $dot;
            background-color: $black;
            border-radius: 50%;
            margin-right: $space8;
            @include v-align;
        }

        .item {
            @include v-align;
            width: calc(100% - 6px - #{$space8});
            font-size: 15px;
            padding: $space4;

            &.isEdit {
                padding: 0;
            }

            &.hasError {
                vertical-align: top;

                .input-outer {
                    border-color: $red;
                }
            }
        }

        .input-outer {
            padding: $space4;
            vertical-align: middle;
            border: 1px solid $border;
            padding-left: $space8;
            padding-right: $space8;
        }

        input {
            width: 100%;
            height: 100%;
            font-size: 15px;
            padding: 0;
        }
    }

    .row-btns {
        text-align: left;
    }

    .ant-btn {
        margin: 0 $space 0 0;

        &:hover {
            margin-right: $space;
        }
    }
}

// 新增表單 (特質 + 擅長工具)
.form-feature {
    .btn-clone {
        font-size: $fontSize14;
        text-align: right;
        color: $blue;
        margin-bottom: 0;

        span {
            cursor: pointer;
        }

        * {
            font-size: $fontSize14;
        }
    }

    // 多筆
    .itemsWrap {
        max-height: 500px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        margin-right: -($space / 2);
        margin-left: -($space / 2);

        .row {
            flex: 0 0 calc(100% / 2 - #{$space});
            margin: 0 ($space / 2) 10px;
        }
    }
}
