@import '../../utils/variables';

.upload-warning-box {
    margin-bottom: $space12;
    padding: $space12 $space;

    * {
        font-size: 15px;
    }

    .anticon {
        margin-top: $space4;
        margin-right: 10px;
    }

    .ant-alert-description {
        padding-left: $space24;

        li {
            list-style: disc;
        }
    }
}

.fileUpload {
    display: block;

    .btn-upload .anticon-plus {
        $font: 30px;

        font-size: $font;

        * {
            font-size: $font;
        }
    }
}

.ant-upload-list-picture-card-container,
.ant-upload-select {
    $w: 100px;
    $h: 112px;
    width: auto;
    min-width: $w;
    max-width: $w;
    height: auto;
    min-height: $h - 3px;
    max-height: $h - 3px;
}

.fileItemWrap {
    border: 1px solid $border;
    padding: $space4 $space8;
    cursor: pointer;

    * {
        line-height: 1.4;
        color: $black;

        &:not(.anticon-delete) {
            font-size: 14px;
        }
    }

    .upload-file-avatar {
        $font: 40px;
        font-size: $font;
        text-align: center;
        margin-bottom: 0;

        * {
            font-size: $font;
        }
    }

    .upload-file-name {
        color: $blue;
        margin-bottom: 0;
        @include ellipsisText;
    }

    .upload-file-info {
        * {
            color: $grayDark;
        }

        .size {
            font-size: 13px;
        }

        .action {
            float: right;
            font-size: $fontSize16;

            * {
                font-size: $fontSize16;
            }
        }
    }
}
