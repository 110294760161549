@import '../../utils/variables';
@import './MyWorkLog';
@import './MyTasks';
@import './TodayDayoff';

$leftGrid: 1080px;
$calendarContent: 892px;
$myTask: 520px;
$cardTitle: 49px;
$flowChart: 360px;

@mixin resetLoading {
    .loadingWrap {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin resetNoData {
    .noData {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.home-page {
    .fas {
        font-size: $fontSize20;
        margin-right: $space8;
    }

    svg[data-icon] {
        font-size: $fontSize20;
        margin-right: $space4;
    }

    .cardItem {
        &:not(:last-child) {
            margin-bottom: $space;
        }
    }

    .small-text {
        font-size: $fontSize14;
        color: $grayDark;
        letter-spacing: 1px;
    }
}

// 工時紀錄行事曆
.cardItem-myWorkLog {
    height: 100%;
    @include resetLoading;

    &.cardsWrap {
        .ant-card-body {
            // min-height: 844px;
            padding: $space16 0 0 0;
        }
    }
}

// 執行中的項目
.cardItem-myTask {
    &.cardsWrap {
        .ant-card-body {
            min-height: $myTask;
            max-height: $myTask;
            padding: 0;
            overflow-y: auto;
        }
    }
}

// 今日休假人員
.cardItem-dayoff {
    &.cardsWrap {
        .ant-card-body {
            min-height: calc((#{$leftGrid} - (#{$cardTitle} + #{$myTask} + #{$space}) - #{$cardTitle}));
            max-height: calc((#{$leftGrid} - (#{$cardTitle} + #{$myTask} + #{$space}) - #{$cardTitle}));
            padding: $space16 0;
            overflow-y: auto;
        }
    }

    &.only-one {
        height: 100%;
    }
}
