@import '../utils/variables';

@mixin selectedDate ($bgColor: $blue) {
    .date,
    .ant-picker-calendar-date-value {
        color: $black;
        background-color: $bgColor;
    }
}

@mixin targetToday {
    @include selectedDate;

    .date,
    .ant-picker-calendar-date-value {
        color: $white;
    }
}

@mixin opacityDate {
    &:not(.ant-picker-cell-in-view) {
        opacity: .5;
    }
}

@mixin setItemsMinHeight {
    .items {
        min-height: calc(150px + 24px);
    }
}

@mixin datepickerTargetDate ($bgColor: $blue) {
    .date-picker-cell {
        color: $white;
        background-color: #{$bgColor};
        border-radius: 50%;
    }
}

@mixin weekendTextColor {
    &:first-child,
    &:last-child {
        color: $red;
    }
}

@mixin simpleItem {
    font-size: $fontSize14;
    text-align: left;
    border-radius: 2px;
    margin-bottom: $space4;
    margin-right: $space16;
    padding: 0 $space8;
}

.calendarWrap {
    // 月
    &.ant-picker-calendar {
        text-align: right;
        background-color: transparent;
        margin-top: -#{$height};

        .ant-picker-panel {
            .ant-picker-body {
                background-color: $white;
                padding: 0;

                th {
                    text-align: center;
                    background-color: $tableHead;
                    padding: $space12 10px;
                }
            }
        }

        .ant-picker-calendar-header {
            text-align: left;
            margin: 0 0 $space;
            padding: 0;
            @include v-align;

            > * {
                margin-left: $space12;
            }

            .ant-select-selector,
            .ant-radio-button-wrapper {
                min-width: 80px;
                height: $height;
                line-height: $height;
            }

            .ant-select-selector {
                align-items: center;
            }

            .ant-radio-button-wrapper {
                text-align: center;
            }

            .ant-radio-group {
                margin-left: $space12;
            }

            .ant-picker-calendar-year-select {
                min-width: 100px;
            }

            .ant-select-selection-item {
                line-height: 32px;
            }

            .ant-select-arrow {
                margin-top: -#{$space8};
                right: $space12;
            }
        }

        .ant-picker-cell {
            border: 1px solid $grayLight;
            border-bottom: 0;
            vertical-align: top;
            padding: 0;
            @include opacityDate;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }

            &:not(.ant-picker-cell-today):not(.ant-picker-cell-selected-day) {
                &:hover {
                    .date {
                        background-color: $grayLight;
                    }
                }
            }
        }

        .ant-picker-cell-selected {
            .date {
                background-color: $gray;
            }
        }

        .ant-picker-cell-today {
            .date {
                color: $white;
                background-color: $blue;
            }
        }
    }

    // 年
    &.ant-picker-calendar-full {
        .ant-picker-month-panel {
            .ant-picker-cell.ant-picker-cell-in-view {
                border: 0;
                border-bottom: 1px solid $grayLight;
                padding-bottom: $space;

                &:not(:last-child) {
                    border-right: 1px solid $grayLight;
                }

                &:hover,
                &:focus,
                &:active {
                    outline: 0;

                    .ant-picker-cell-inner.ant-picker-calendar-date {
                        background-color: transparent;
                        border: 0;
                        cursor: default;
                    }

                    .ant-picker-calendar-date-value {
                        background-color: $border;
                    }

                    .ant-picker-calendar-date-today {
                        .ant-picker-calendar-date-value {
                            background-color: $blue;
                        }
                    }
                }

                .ant-picker-cell-inner {
                    border: 0;
                    margin: 0;
                    padding: 0;
                }
            }

            .ant-picker-calendar-date-value {
                height: auto;
                color: $black;
                background-color: $grayLight;
                display: inline-block;
                margin-bottom: $space;
                padding: $space4 $space16;
                transition: all .3s ease-in-out;

                &:hover {
                    cursor: default;
                }
            }

            .ant-picker-cell-selected {
                .ant-picker-calendar-date {
                    background-color: transparent;
                }

                .ant-picker-calendar-date-value {
                    color: $black !important;
                    background-color: $gray;
                }

                .ant-picker-calendar-date-today {
                    .ant-picker-calendar-date-value {
                        color: $white !important;
                    }
                }
            }

            .ant-picker-calendar-date-today {
                .ant-picker-calendar-date-value {
                    color: $white;
                    background-color: $blue;
                }
            }

            .ant-picker-calendar-date-content {
                min-height: 160px;
                letter-spacing: 1px;
                text-align: left;
            }
        }
    }
}

.dates {
    min-height: 160px;
    text-align: center;
    padding: $space8 0;

    .date {
        $circle: 1.8em;
        width: $circle;
        height: $circle;
        line-height: $circle;
        font-size: $fontSize14;
        color: #000;
        border-radius: 50%;
        display: inline-block;
        margin-bottom: $space8;
        position: relative;
        transition: all .3s ease-in-out;
        cursor: default;
    }

    .name {
        color: $white;
        background-color: $blue;
        @include simpleItem;
    }

    .holiday-dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $blue;
        position: absolute;
        top: 10px;
        right: 6px;
    }

    // 事項
    .items {
        $cell: 160px;
        height: 100%;
        min-height: 150px;
        text-align: left;
        @include calendarItem;
    }

    .item {
        @include simpleItem;
    }

    .moreItems {
        font-size: 13px;
        font-weight: bold;
        color: #000;
        cursor: pointer;
    }
}

.isHoliday {
    &.weekendWithoutName {
        @include setItemsMinHeight;
    }

    .name,
    .holiday-dot {
        background-color: $red;
    }
}

[data-work-day] {
    color: $black;
}

.notHoliday {
    @include setItemsMinHeight;
}

.ant-picker-selected-day {
    @include selectedDate($gray);

    .date {
        color: $black;
    }
}

.ant-picker-today {
    @include targetToday;
}

.ant-picker-month-panel-tbody {
    tr:last-child {
        .ant-picker-month-panel-cell {
            border-bottom: 0;
        }
    }
}

.month-mode-list {
    color: $white;
    background-color: $red;
    border-radius: 2px;
    margin-right: $space16;
    margin-bottom: $space4;
    padding: 1px $space16;

    &.notHoliday {
        background-color: $blue;
    }

    > * {
        font-size: 15px;
        display: inline-block;
    }

    .label {
        min-width: 80px;
    }
}

// Calendar Form
.calendarLightbox {
    .btn-delete {
        font-size: $fontSize18;
        font-weight: normal;
        color: $grayDark;
        margin-top: -2px;
        padding: $space8;
        position: absolute;
        right: $space40;
        cursor: pointer;
        @include alignY;
    }
}

.form-calendar {
    margin-right: 10px;
}

.range-picker-box,
.date-picker-box {
    * {
        font-size: $fontSize14;
    }

    th {
        @include weekendTextColor;
    }

    .ant-picker-cell {
        color: $black;
        @include opacityDate;

        .isHoliday {
            color: $red;
        }
    }

    .date-picker-cell {
        $circle: 1.7em;
        width: $circle;
        height: $circle;
        line-height: $circle;
        display: inline-block;
        position: relative;
        z-index: 1;
    }

    .ant-picker-cell-in-view {
        @include weekendTextColor;

        &:not(.ant-picker-cell-selected):not(.ant-picker-cell-today) {
            &:hover {
                @include datepickerTargetDate($grayLight);

                .date-picker-cell {
                    color: $black;
                }
            }
        }
    }

    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end {
        @include datepickerTargetDate($yellow);
    }

    .ant-picker-cell-selected {
        @include datepickerTargetDate($yellow);
    }

    .ant-picker-cell-today {
        @include datepickerTargetDate;
    }

    .ant-picker-cell-disabled {
        &:not(.ant-picker-cell-today) {
            .date-picker-cell {
                color: $grayDark;
                position: relative;
                z-index: 2;
            }
        }
    }
}

.noticeBox {
    &.ant-alert.ant-alert-no-icon {
        height: $height;
        line-height: 1;
        letter-spacing: 1px;
        color: $black;
        padding: $space8 $space16;
        @include v-align;
        @include alignBox;

        > * {
            @include v-align;
        }
    }
}

// popconfirm 詳細資訊
.ant-popconfirm.detail-tip {
    .ant-popover-inner-content {
        padding: $space;
    }

    .anticon-exclamation-circle,
    .btn-ok {
        display: none;
    }

    .ant-popover-message {
        margin-bottom: $space;
        padding: 0;
    }

    .ant-popover-message-title {
        padding-left: 0;
    }

    .date-title {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .ant-popover-buttons {
        text-align: center;
    }

    .btn-close {
        height: auto;
        margin-left: 0;
        padding: 3px $space30;

        span {
            font-size: 15px;
        }
    }

    .ant-table-column-sorters {
        padding: 0;
    }
}
