@import '../../utils/variables';

@mixin resetTable {
    .ant-table-cell {
        font-size: 15px;
        padding: $space4;

        * {
            font-size: 15px;
        }
    }
}

@mixin summaryCell {
    .ant-table-summary {
        background-color: $white;
    }

    .table-summary {
        text-align: right;

        .ant-table-cell {
            border-top: 3px double;
            border-bottom: 0;
            padding-bottom: 7px;

            &:first-child {
                text-align: center;
            }
        }

        .warning-text {
            color: $red;
        }

        // 個人頁
        .ant-table-cell.summary-label,
        .total-hours {
            text-align: left;
        }
    }

    .balance {
        color: $green;
    }
}

@mixin resetSecondaryButton ($borderColor: $border, $textColor: $black) {
    color: $textColor;
    background-color: $white;
    border-color: $borderColor;
}

.timelineDataTable {
    @include resetTable;
    @include summaryCell;

    &:nth-child(2) {
        margin-top: $space;
    }
}

.timelineDataTitle {
    font-size: 24px;
    font-weight: bold;
    color: $black;
    display: inline-block;
    margin-bottom: $space;

    &:hover {
        text-decoration: underline;
        color: $black;
    }
}

.timelineData-group-budget {
    .ant-table-cell {
        border-right: 1px solid $border1;
    }

    th.ant-table-cell {
        &[colspan="3"] {
            border-bottom: 1px solid $border1 !important;
        }
    }
}

.timeline {
    overflow: hidden;
}

.timelineDataSearch {
    margin-bottom: $space;
    @include clearBox;
}

.searchResult {
    float: right;
    margin-top: calc((32px - 27px) / 2);
}

.searchForm {
    display: inline-block;

    > * {
        @include v-align;

        &:not(:last-child) {
            margin-right: $space16;
        }
    }

    .title {
        @include v-align;
    }

    .checkboxesWrap {
        max-width: 500px;
        white-space: nowrap;
        overflow-x: auto;
    }

    [class*="btn-"] {
        padding: $space4 $space30;

        * {
            font-size: 15px;
        }
    }

    .btn-primary {
        margin-right: 0;

        &:hover {
            margin-right: 0;
        }
    }

    .btn-secondary {
        @include resetSecondaryButton;

        &:hover,
        &:active,
        &:focus {
            @include resetSecondaryButton($blue, $blue);
        }
    }
}

.timelineDataWrap {
    overflow-x: scroll;
}

.timeline_tooltip_wrap {
    width: 300px;

    * {
        font-size: 15px;
    }

    .title {
        border-bottom: 1px solid $border;
        padding: $space12;
    }

    .name {
        font-weight: bold;
    }

    .small-text {
        font-size: 13px;
        color: $grayDark;
    }

    .square {
        $w: 10px;
        width: $w;
        height: $w;
        display: inline-block;
    }

    .assignee {
        float: right;
    }

    .info {
        padding: 0 $space12;

        > * {
            margin-bottom: 10px;
        }
    }

    .label {
        font-weight: bold;
    }
}

// 個人專案時間軸
.timelineData-project-list,
.timelineData-todo-list {
    .ant-table-cell:first-child {
        max-width: 200px;

        * {
            display: inline-block;
        }
    }

    .project-status {
        font-size: 13px;
        line-height: 1.2;
        color: $black;
        margin-right: $space4;
        padding: 2px;
        cursor: default;
    }
}
