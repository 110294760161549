@import '../../utils/variables';

$hoverColor: #D5D5D5;
$activeColor: #AAA;

@mixin setColSpace ($col: 6) {
    flex: 0 0 calc(100% / #{$col} - #{$space});
}

.breadcrumbs + .btn-primary {
    min-width: 120px;
    min-height: 34px;
    margin: $space12 0 $space;

    .anticon + span {
        margin-left: $space4;
    }
}

.settingColumns {
    display: flex;
    flex-wrap: wrap;
    margin-right: -#{$space};

    .column {
        text-align: center;
        color: $black;
        background-color: $white;
        border-radius: $space8;
        margin-bottom: $space;
        margin-right: $space;
        padding: $space30 $space16;
        transition: all .3s ease-in-out;
        overflow: hidden;
        cursor: pointer;
        @include setColSpace;
        @media screen and (max-width: 1400px) {
            $col: 5;
            @include setColSpace($col);
        }
        @media screen and (max-width: #{$Laptop}px) {
            $col: 4;
            @include setColSpace($col);
        }
        @media screen and (max-width: #{$Tablet}px) {
            $col: 3;
            @include setColSpace($col);
        }
        @media screen and (max-width: #{$Phone}px) {
            $col: 2;
            @include setColSpace($col);
        }
        @media screen and (max-width: #{$PhoneSM}px) {
            $col: 1;
            @include setColSpace($col);
        }

        &:hover {
            color: $black;
            background-color: $hoverColor;
        }

        &:active {
            background-color: $activeColor;
        }

        i {
            font-size: 30px;
            margin-bottom: $space;
        }

        p {
            margin-bottom: 0;
            // @include ellipsisText;
        }
    }
}
