@import '../utils/variables';

.betty-test {
    width: 300px;

    &.ant-picker-calendar {
        margin-top: 0;

        .ant-picker-panel .ant-picker-body th {
            font-size: $fontSize14;
            padding: 8px;
        }

        .ant-picker-cell {
            width: 40px;
            height: 40px;
            border: 0;
        }
    }

    .ant-picker-calendar-year-select,
    .ant-picker-calendar-mode-switch {
        display: none;
    }

    .dates {
        width: 100%;
        height: 100%;
        min-height: auto;
        padding: 0;
        background-color: #5cfff4;
        @include alignBox;

        .date {
            margin-bottom: 0;
        }

        .name {
            display: none;
        }
    }

    .ant-picker-cell-disabled {
        .dates {
            background-color: transparent;
        }
    }

    .isHoliday {
        .date {
            color: $red;
        }
    }

    .ant-picker-cell-in-view:first-child,
    .ant-picker-cell-in-view:last-child,
    .ant-picker-cell-in-view:first-child,
    .ant-picker-cell-in-view:last-child {
        .date {
            color: $red;
        }
    }

    .ant-picker-cell-in-view [data-holiday="0"] .date {
        color: $black;
    }
}
