@import '../../utils/variables';

// ToDoList.scss 在 Detail.scss 引入
@import './Detail';
@import './OtherFeeList';
@import './WorkLogList';

.projectContentWrap {
    position: relative;
    @include btnCreate;

    &:not(.content-type-single) {
        padding-bottom: $pagination + $space;
    }

    // Reset Table
    .column,
    .ant-spin-nested-loading,
    .ant-spin-container {
        position: initial;
    }

    .tagsWrap {
        padding: 3px $space8;
    }

    .column-left {
        margin-right: $space;

        .ant-table {
            min-height: $projectList;
            background-color: $white;
        }
    }

    a[href] {
        &:hover {
            text-decoration: underline;
        }
    }

    .btn-primary {
        &:hover,
        &:focus {
            margin-right: 0;
        }
    }

    // 分頁
    .pagination {
        width: 100%;
        text-align: right;
        position: absolute;

        &:first-child {
            top: 0;
        }

        &:last-child {
            bottom: 0;
        }

        .ant-pagination-total-text {
            width: auto;
            float: left;
            margin-top: calc((#{$pagination} - 30px) / 2);
            margin-bottom: 0;
        }
    }
}

.withoutTableHeader {
    thead {
        display: none;
    }
}

.col-projectName {
    $tag: 70px;
    cursor: pointer;

    .title {
        position: relative;
    }

    .project-name {
        width: calc(100% - (#{$tag} + 10px));
        font-weight: bold;
        margin-bottom: $space4;
        @include ellipsisText;
    }

    .tagsWrap {
        max-width: $tag;
        font-size: 13px;
        padding: 0 6px;
        position: absolute;
        top: 2px;
        right: 0;
    }

    .small-info {
        * {
            font-size: $fontSize14;

            &:not(a) {
                color: $grayDark;
            }
        }

        .budge {
            letter-spacing: 0.5px;
        }

        .manager {
            * {
                line-height: 1;
                @include v-align;
            }
        }

        .fas {
            font-size: 13px;
            margin-right: 6px;
        }

        a {
            @include v-align;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

// 專案異動紀錄
.hasChangedLog {
    color: $white;
    background-color: $red;
    border-radius: 50%;
    padding: 3px;
    position: absolute;
    right: $space16;
    top: 32px;

    * {
        font-size: $fontSize14;
    }
}
