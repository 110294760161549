@import '../../utils/variables';

.members-header {
    padding-left: 220px;
    margin-bottom: $space;
    @include clearBox;

    > span {
        line-height: 34px;
    }

    .btn-primary {
        float: right;
        margin-right: 0;

        &:hover {
            margin-right: 0;
        }
    }

    .btn-primary {
        &:hover,
        &:focus {
            margin-right: 0;
        }
    }
}

.member-list {
    a {
        display: inline-block;
    }

    thead {
        cursor: default;
    }

    .ant-table-tbody {
        background-color: $white;

        * {
            font-size: 15px;
        }
    }

    .tagsWrap {
        margin-top: 0;
        padding: 2px $space8;
    }

    .col-names {
        a {
            @include v-align;
        }

        .small-text {
            font-size: $fontSize14;
            color: $grayDark;
            vertical-align: middle;
            margin-left: $space4;
        }
    }

    .col-userId {
        a {
            padding: 2px 6px;
        }

        .small-text {
            font-size: $fontSize14;
            color: $grayDark;
            margin-left: 0;
        }
    }

    .col-action {
        > * {
            color: $blue;
        }

        span {
            margin-right: 10px;
        }
    }

    .birthday-user {
        color: $red;
        margin-left: 10px;
    }

    .copy-text {
        color: $grayDark;
        display: inline-block;
        margin-left: 2px;
        padding: 0 $space4;
        cursor: pointer;
        transition: all .4s linear;

        &:hover {
            transform: scale(1.2);
            color: $black;
        }
    }

    .expandDetailWrap {
        padding: 10px $space;
        cursor: default;

        p {
            line-height: 1.6;
            margin-bottom: 0;
            display: flex;

            &:not(:last-child) {
                > span {
                    border-bottom: 0;
                }
            }

            > span {
                flex: 1;
                border: 1px solid $border;
                padding: 2px $space8;

                &:first-child {
                    border-right: 0;
                }
            }
        }

        .label {
            font-weight: bold;
            text-align: right;
            flex: 0 0 24%;
        }

        a {
            @include v-align;
        }

        .more-detail a {
            text-decoration: underline;
        }
    }

    .groups-column {
        @include flexAlign;

        > span {
            flex: 1;
        }
    }

    .btn-groupUpdate {
        height: auto;
        margin: 0;
        padding: 0 6px;

        > * {
            font-size: 13px;
        }

        &:hover,
        &:active,
        &:focus {
            margin: 0;
        }
    }
}
