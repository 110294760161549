@import '../../utils/variables';

.projectSetting {
    .tagsWrap {
        min-width: 33px;
        min-height: auto;
        height: 26px;
        font-size: 15px;
        margin: 0;
        padding-top: 1px;
        @include alignBox;
    }

    .col-name {
        .tagsWrap {
            float: right;
        }
    }

    .col-colorText {
        text-align: center;
    }

    .col-priority {
        div {
            max-width: 40px;
            text-align: center;
        }
    }
}
