@import '../../utils/variables';

.workLogTypeWrap {
    .ant-table-cell {
        padding: $space8;

        &:not(.col-actions):not(.col-name):not(.col-code) {
            text-align: center;
        }
    }

    .tagsWrap {
        min-width: 33px;
        min-height: auto;
        height: 26px;
        margin: -1px 0 0;
    }

    .col-name {
        .tagsWrap {
            font-size: 15px;
            float: right;
            padding-top: 1px;
            @include alignBox;
        }
    }

    .col-colorText {
        .tagsWrap.isWhite {
            border: 1px solid $border;
        }
    }

    .col-code {
        font-size: 15px;
    }

    th.ant-table-cell {
        font-size: 15px;
    }
}

.workLogTypeLightbox {
    .items {
        display: flex;

        > * {
            flex: 1;
            &:not(:last-child) {
                margin-right: $space;
            }
        }
    }

    .checkboxWrap {
        * {
            font-size: 15px;
        }
    }
}
