@import '../utils/variables';

@mixin menuSpace ($y: 0px) {
    padding: 10px $y;
}

@mixin menuLayout {
    position: relative;
    @include menuSpace($space40);

    &:hover {
        &:not(.active) {
            background-color: $blackLight;
        }
    }

    a {
        position: initial;

        &:before {
            content: '';
            background-color: transparent;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

.navbar {
    background-color: $black;
    padding-top: 30px;
    padding-bottom: 30px;

    .navbar-wrap {
        color: $white;

        * {
            font-size: 17px;
        }
    }

    .active {
        background-color: $green;
        cursor: default;

        a {
            cursor: default;
        }
    }
}

.menu {
    line-height: 1.6;
    margin-bottom: $space30;

    &.menu-single {
        @include menuLayout;
    }

    .anticon {
        margin-right: $space;
    }

    .small-title {
        font-size: $fontSize14;
        font-weight: normal;
        color: $grayDark;
        padding: 0 $space40;
        cursor: default;
    }

    .sub-menu {
        color: $white;
        @include menuLayout;
    }
}

.menu-logout {
    padding: 10px calc(20px * 2);

    .btn-primary {
        width: 100%;
        height: 36px;
    }
}
