@import '../../utils/variables';

.other-fee {
    color: $blue;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.other-fee-card {
    margin-bottom: $space16;

    .ant-card-body {
        padding: $space12;
    }

    .ant-card-meta-detail {
        .ant-card-meta-title {
            margin-bottom: $space4;
            padding-right: 0;
        }
    }

    .btn-delete {
        font-size: $fontSize14;
        font-weight: normal;
        color: $blue;
        float: right;
        margin-top: 2px;
        cursor: pointer;
    }

    .ant-card-meta-description * {
        font-size: 15px;
        color: $grayDark;
    }

    .notes {
        font-size: $fontSize14;
    }
}

// 另計支出表單
.other-fee-form {
    .title {
        font-size: $fontSize16;
    }

    .items {
        &:last-child {
            align-items: flex-end;
            margin-bottom: $space30;

            .row {
                margin-bottom: 0;
            }
        }

        .row:not(:last-child) {
            margin-right: 10px;
        }

        .item {
            flex: 0 0 60%;
        }
    }

    .row {
        margin-bottom: 10px;

        * {
            font-size: 15px;
        }
    }

    .btn-submit {
        height: 34px;
    }
}
