@import '../../utils/variables';

.projects {
    [class*="btn-"]:not(.btn-secondary) {
        &:active,
        &:focus {
            margin-right: 0;
        }
    }
}

.project-lists {
    .ant-table-column-sorters {
        padding: 0;
    }

    .ant-table-cell,
    th.ant-table-column-has-sorters {
        font-size: 15px;
        padding: $space8 10px;

        &:first-child {
            padding-left: $space16;
        }

        *:not(.tagsWrap) {
            font-size: 15px;
        }

        a {
            padding: 2px $space8 2px 0;
            @include v-align;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    th.ant-table-cell {
        height: auto;
        min-height: 40px;
    }

    [class*="btn-"] {
        height: auto;
        padding: 2px $space8;

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    .btn-danger {
        margin-right: 0;
    }

    .tagsWrap {
        padding: 0 $space4;
        margin: 0;
    }

    .ant-table-cell {
        padding: 6px;

        .user {
            font-size: $fontSize14;
        }
    }
}

.projects {
    .project-header {
        align-items: center;
        margin-bottom: $space;
    }

    .project-summary {
        margin-left: 220px;
    }

    .summary-text {
        span {
            margin: 0 10px 0 $space8;
        }
    }

    .event {
        text-align: right;
    }

    .work-status {
        font-size: 13px;
        color: $grayDark;
        display: inline-block;
        margin-left: $space4;

        &.is-leave {
            color: $red;
        }
    }

    .ant-picker-range {
        &.range-picker-input {
            height: 34px;
            padding: 4px 12px;

            input {
                font-size: 13.5px;
            }
        }
    }

    .btn-primary {
        margin-right: 0;

        &:hover {
            margin-right: 0;
        }
    }

    .settings {
        margin-right: $space12;
        position: relative;
    }

    .setting-coloumns-wrap {
        min-width: 150px;
        text-align: left;
        background-color: $white;
        box-shadow: 2px 0 8px 0px rgba(0, 0, 0, 0.2);
        padding: $space12 $space;
        position: absolute;
        right: 0;
        z-index: 1;

        > * {
            display: block;
            margin-left: 0;
        }
    }

    .tagsWrap {
        margin: 0;
    }

    .expandDetailWrap {
        padding-left: $space40;
    }

    .col-date {
        font-size: 14px;
    }
}
