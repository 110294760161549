@import '../../utils/variables';
@import './PersonalSkillList';
@import './Settings';
@import './ManageLabel';

// Header
.skill-header {
    text-align: right;
    margin-bottom: $space;

    .btn-primary:last-child {
        margin-right: 0;
    }
}

.skillTab {
    min-height: calc(100vh - 112px);
    .checkboxLists {
        .tagsWrap {
            border: 1px solid $grayLight !important;
        }

        .circle {
            width: 8px;
            height: 8px;
            border: 1px solid $border;
            display: inline-block;
            vertical-align: 2px;
            border-radius: 50%;
            margin-right: $space4;
        }
    }

    .label {
        @include v-align;
        font-size: $fontSize14;
        background-color: $gray;
        margin-right: 10px;
        margin-bottom: 6px;
        padding: 1px 6px;
    }
}

// 領域技能表
.skillWrap {
    border: 1px solid $border;

    .btn-primary:last-child {
        margin-right: 0;
    }

    .skill-row {
        display: flex;

        &:last-child {
            .skill-item:last-child,
            .skill-row--right,
            .skill-row--left {
                border-bottom: 0;
            }
        }
    }

    .skill-row-title {
        font-weight: bold;

        .no-item {
            line-height: 50px;
            padding: 0 $space !important;
        }
    }

    [class*="skill-row--"] {
        text-align: center;
        flex: 1;
        justify-content: center;

        &:not(.skill-row--middle) {
            @include flexAlign;
        }
    }

    .skill-row--left {
        border-right: 1px solid $border;
        border-bottom: 1px solid $border;
    }

    .skill-row--middle {
        flex: 0 0 50%;
    }

    .skill-row--right {
        border-bottom: 1px solid $border;
    }

    .skill-item {
        display: flex;
        border-bottom: 1px solid $border;

        > * {
            border-right: 1px solid $border;
            flex: 1;
        }

        .name {
            text-align: left;
            padding: 10px $space;
            @include flexAlign;
        }

        .members {
            text-align: left;
            padding: $space8 $space;
        }

        .item-wrap {
            letter-spacing: 1px;
            color: $blue;
            cursor: pointer;

            &:hover {
                .member {
                    text-decoration: underline;
                }
            }

            > span {
                margin-right: $space4;
            }

            .member-status {
                width: 22px;
                color: $gray;
                text-align: center;
                display: inline-block;
                margin-right: 2px;
            }

            .no-status {
                padding-left: $space24;
            }
        }

        .small-text {
            color: $grayDark;
            margin-left: 0;
        }
    }

    .no-item {
        min-height: 50px;
    }
}

// 評分表
.slider {
    $width: 40px;

    > * {
        display: inline-block;
        vertical-align: -30px;
    }

    .ant-slider-track {
        background-color: $blue;
    }

    .ant-slider-handle {
        border-color: $blue;
    }

    .ant-slider-mark-text {
        font-size: $fontSize14;
    }

    .ant-slider {
        margin-left: 0;
        margin-right: 0;
    }

    .score-slider {
        width: calc(100% - #{$width} - #{$space4});
    }

    .small-text {
        width: $width;
        font-size: $fontSize14;
        text-align: right;
        margin-left: $space4;
    }
}

// 管理技能與領域表單
.skill-wrap {
    border: 1px solid $border;

    > * {
        padding: 0 $space12;
    }

    .skill-item-wrap {
        max-height: 190px;
        padding: $space4 6px $space4 $space12;
        overflow-y: auto;

        .noData {
            padding: 10px 0;
        }

        .ant-empty-image {
            height: 80px;
        }

        .ant-empty-description {
            font-size: $fontSize16;
            margin-top: 10px;
        }
    }

    .skill-items {
        margin: $space8 0;

        span {
            font-size: 15px;
        }

        .small-text {
            font-size: 13px;
            color: $grayDark;
            margin-left: 6px;
        }
    }

    .skill-action {
        text-align: right;
        border-bottom: 1px solid $border;
        padding: 2px $space8;

        .anticon {
            vertical-align: 0;
            padding: $space4;
            cursor: pointer;

            * {
                font-size: 12px;
            }
        }
    }

    .btn-actions {
        float: right;

        * {
            font-size: $fontSize14;
        }

        > * {
            color: $grayDark;
            margin-left: 2px;
            padding: $space4;
        }
    }
}

// 技能表單
.skillForm {
    $btnSection: 100px;
    margin-top: $space8;
    margin-bottom: $space8;

    > * {
        @include v-align;
    }

    input {
        width: calc(100% - 100px - #{$space16} - #{$space8});
        font-size: $fontSize14;
        height: 28px;
        border: 1px solid $border;
        margin-left: $space8;
        padding: 4px $space8;
    }

    .btn-actions {
        width: $btnSection;
        text-align: right;
        float: none;

        [class*="btn-"] {
            height: auto;
            margin-left: 6px;
            padding: 0 6px;
        }

        .btn-primary {
            color: $white;
        }

        .btn-secondary:hover {
            border-color: $grayDark;
        }
    }
}

// 新增員工技能
.member-skill-form {
    .field {
        height: auto;
    }

    .notice-text {
        font-size: 12px;
        color: $blue;
    }

    .tagsWrap {
        border: 1px solid $grayLight !important;
        margin-left: 0;
    }

    .ant-select-selection-placeholder {
        font-size: 15px;
    }

    .warning-text {
        font-size: 15px;
        color: $red;
        margin-left: $space4;
    }
}

// 所有員工技能評分
.member-skill-list {
    a {
        display: inline;
    }

    .small-text {
        color: $grayDark;
    }

    .tagsWrap {
        padding: 1px 3px;
    }

    .personal-skill-row {
        margin: 6px 0;

        span {
            font-size: 15px;
        }

        .ant-progress-text {
            font-size: $fontSize14;
            text-align: right;
            color: #00000073;
            margin-left: 2px;
        }

        .score {
            max-width: 40%;
            margin-left: $space8;
            @include place;
        }
    }

    .col-name {
        max-width: 200px;

        .name {
            .member-status {
                width: 22px;
                color: $gray;
                display: inline-block;
                margin-right: 2px;
            }

            .no-status {
                padding-left: $space24;
            }
        }
    }

    .col-id {
        max-width: 100px;
    }

    .col-feature,
    .col-tool {
        max-width: 200px;
    }
}
