@import '../../utils/variables';

.row-label-item {
    margin-bottom: $space;

    p {
        font-size: $fontSize18;
        margin-bottom: $space4;
    }

    .item-wrap {
        display: flex;
        flex-wrap: wrap;
        padding-left: $space16;

        * {
            font-size: 15px;
        }

        > * {
            line-height: 2;
            flex: 0 0 calc(100% / 2 - 10px);
            margin-left: 0;
            margin-right: 10px;
        }
    }
}
