@import '../../utils/variables';

.calendar-setting {
    margin-top: $space;

    .dates {
        min-height: 150px;
        padding: $space16 0;

        .date {
            margin-bottom: $space16;
        }

        .name {
            font-size: 15px;
        }
    }
}
