@import '../../node_modules/npm-font-open-sans/open-sans';
@import '../utils/variables';

// Rest HTML Default
* {
    font-family: 'Open Sans', 'Microsoft JhengHei', Arial, Helvetica, sans-serif;
    font-size: $fontSize16;
}

body {
    color: $black;
    margin: 0;

    .ant-popconfirm {
        z-index: 1000;
    }
}

ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;

    li {
        list-style: none;
    }
}

.content {
    background-color: $white;
    background-color: $grayLight;
    position: relative;
    overflow-x: auto !important;

    .main {
        min-width: 1200px;
        height: 100%;
        padding: 30px $space;
        position: relative;
    }

    .btn-primary {
        @include resetButton;
    }

    .btn-secondary {
        @include resetButton($green);
    }

    .btn-danger {
        @include resetButton($red);
    }

    .warning-text {
        color: $red;
    }

    .greate-text {
        color: green;
    }

    .ant-btn[disabled] {
        color: #00000040;
        background-color: $grayLight;
        border-color: $border;

        &:hover,
        &:focus,
        &:active {
            color: #00000040;
            background-color: $grayLight;
            border-color: $border;
        }
    }

    .back-to-top {
        right: 32px;
        bottom: $space;

        .ant-back-top-content {
            text-align: center;
            color: $white;
            background-color: rgba(0, 0, 0, .4);
            border-radius: 50%;

            * {
                font-size: 26px;
            }
        }
    }

    .pagination {
        text-align: right;
        margin-top: $space;

        .ant-pagination-total-text {
            width: auto;
            margin-right: $space !important;
            margin-bottom: 0;
        }
    }
}

.noHeader {
    .main {
        margin-top: 0;
    }
}

.hasCreateBtn {
    .back-to-top {
        bottom: 102px;
    }
}

a {
    color: $blue;
    display: block;
}

input::placeholder,
input.ant-calendar-picker-input::placeholder {
    color: #959595;
    color: $border;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

// 麵包屑
.breadcrumbs {
    margin-bottom: $space;

    * {
        font-size: $fontSize14;
        color: $blue;
        display: inline-block;
    }

    svg[data-icon] {
        font-size: 12px;
        margin: 0 $space8;
    }

    .current {
        font-weight: bold;
        cursor: default;
    }
}

// Tags
.tagsWrap {
    &.ant-tag {
        min-width: 30px;
        min-height: 20px;
        font-size: $fontSize14;
        font-weight: normal;
        text-align: center;
        margin: -1px $space4 0;
        padding: 0 $space8;
        @include v-align;

        &:not(.log-status) {
            border: 0;
        }
    }
}

// Tooltip
.tooltip {
    .ant-tooltip-inner {
        min-height: auto;
        font-size: 13px;
        padding: 3px $space8;
    }
}

// 頁面施工中、404
.brokenWrap {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > * {
        line-height: 1.6;
        font-size: 26px;
        margin: 0 0 $space;
    }

    .ant-result-title {
        margin-bottom: 10px;
    }

    .ant-result-subtitle {
        font-size: $fontSize16;
        margin-bottom: $space30;
    }

    .btn-back {
        line-height: 0;
        display: inline-block;
    }

    .ant-btn {
        height: auto;
        padding: $space8 $space60;

        * {
            font-size: $fontSize20;
            @include v-align;
        }

        .anticon {
            margin-top: -#{$space4};
        }
    }
}

// No data
.noData {
    background-color: $white;
    margin: 0;
    padding: $space40;
    cursor: default;

    .ant-empty-description {
        font-size: $fontSize18;
        margin-top: $space;
    }
}

// Loading
.loadingWrap {
    text-align: center;
    padding: $space40;

    *:not(.ant-spin-text) {
        font-size: 32px;
    }

    .action {
        width: auto;
        height: auto;
    }

    .ant-spin-text {
        margin-top: $space12;
    }
}

// Loading with icon - temp
.drawer-loading-spin {
    * {
        font-size: 40px;
    }
}

// Message
.ant-message {
    .ant-message-notice {
        text-align: right;
        padding: 10px $space;
    }
}

// Drawers
.drawerWrap {
    .title {
        line-height: 1.4;
        font-size: $fontSize20;
        font-weight: bold;
        margin-bottom: 0;
    }
}

// 技能樹排名
.crown {
    color: gold;
    margin-left: 2px;

    * {
        font-size: $fontSize14;
    }
}

.second-place {
    color: silver;
}

.third-place {
    color: #b78670;
}

// 下載 Excel
.download-excel {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;

    img {
        width: 100%;
    }
}

.guest-box {
    width: 400px;
    background-color: $white;
    padding: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $tableBorder;
}
