@import '../../utils/variables';

@mixin resetCalendarHeader {
    $height: 30px;

    .calendarWrap.ant-picker-calendar {
        .ant-picker-calendar-header {
            margin-bottom: 12px;

            .ant-picker-calendar-year-select {
                min-width: auto;
            }

            .ant-select-selection-item {
                line-height: $height;
            }

            .ant-select-arrow {
                margin-top: -6px;
            }

            .ant-select-selector,
            .ant-radio-button-wrapper {
                min-width: auto;
                height: $height;
                min-height: $height;
            }
        }
    }

    .ant-radio-group {
        display: none;
    }
}

@mixin resetCalendarBody {
    .calendarWrap {
        * {
            font-size: 13px;
        }

        &.ant-picker-calendar {
            margin-top: -30px;

            .ant-picker-panel .ant-picker-body th {
                padding: 4px;
            }

            .ant-picker-cell {
                border-bottom: 1px solid $grayLight;

                &:first-child {
                    border-left: 1px solid $grayLight;
                }

                &:last-child {
                    border-right: 1px solid $grayLight;
                }
            }

            .ant-picker-cell-selected {
                background-color: $gray;
            }
        }

        .ant-picker-content th,
        .ant-picker-content td {
            min-width: auto;
        }
    }

    .dates {
        min-height: 54px;
        padding: 4px 0;

        .date {
            width: 1.5em;
            height: 1.5em;
            line-height: 1.5em;
            margin-bottom: 4px;
        }

        .name,
        .item {
            margin-bottom: 2px;
        }

        .items {
            min-height: auto;
        }

        .item {
            min-height: 5px;
            margin-right: 10px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

@mixin resetTable {
    .ant-table-cell {
        padding: 4px 8px;
    }
}

.workLogListWrap {
    @include resetCalendarHeader;
    @include resetCalendarBody;
    @include resetTable;

    * {
        font-size: 15px;
    }

    .title {
        font-weight: bold;
        margin-right: $space4;
    }

    .small-text {
        font-size: 13px;
        color: $grayDark;
        margin: $space4 0 0 0;
    }
}

.worklog-detail-tip {
    max-width: 30%;

    .ant-popover-inner-content {
        padding: $space16;
    }

    .aDayWrap {
        margin-top: $space12;

        *:not(th) {
            font-size: $fontSize14;
        }
    }

    .col-action {
        > * {
            color: $blue;
            padding: 4px 0;
            cursor: pointer;
        }

        .btn-update {
            margin-right: 10px;
        }
    }

    .noData {
        padding: 10px;

        .ant-empty > * {
            @include v-align;
        }

        .ant-empty-image {
            height: 50px;
            margin-bottom: 0;

            svg {
                width: 80px;
            }
        }

        .ant-empty-description {
            margin-top: 0;
        }
    }

    .selected-date {
        text-align: right;
    }
}
