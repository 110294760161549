@import '../../utils/variables';

.dayoffWrap {
    @include btnCreate;
}

.dayoff-calendar {
    .ant-radio-group {
        display: none;
    }

    .dates {
        .name {
            @include ellipsisText;
        }
    }
}

.showDetail {
    &.items {
        * {
            font-size: 13px;
        }

        .username {
            font-size: $fontSize14;
        }
    }
}

.details {
    .username {
        color: $black;
        display: inline-block;
    }

    a.username {
        &:hover {
            color: $blue;
            text-decoration: underline;
        }
    }

    .btn-cancel {
        &.ant-btn {
            width: 100%;
            min-height: $height;
            margin-bottom: $space;
        }
    }
}

.detailWrap {
    @include calendarItem;

    .ant-collapse-item {
        .ant-collapse-header {
            line-height: 1;
            padding: 10px 32px;

            .ant-collapse-arrow {
                left: 10px;
            }
        }

        .ant-collapse-content {
            padding: 10px 14px 0;
        }
    }

    .item {
        margin-bottom: $space4;
        cursor: default;
        @include clearBox;

        span {
            * {
                @include v-align;
            }
        }
    }

    .tagsWrap {
        margin-left: $space8;
    }
}

.btns-action {
    float: right;

    .ant-btn {
        height: auto;
        line-height: 1;
        flex: 1;
        margin-right: 0;
        padding: 5px 10px;

        &:not(:first-child) {
            margin-left: $space8;
        }

        &:hover,
        &:focus {
            margin-right: 0;
        }
    }

    .btn-secondary {
        @include resetButton($green);
        margin-right: 0;

        &:hover {
            margin-right: 0;
        }
    }
}

.leaveTypeFormWrap {
    .small-text {
        line-height: 1;
        @include v-align;
    }
}

.prompt-dayoff {
    .ant-modal-confirm-body {
        .ant-modal-confirm-content {
            margin: $space16 38px $space40;
        }
    }

    .tagsWrap.dayoff-delete {
        font-size: 15px;
        vertical-align: 0;
        padding-bottom: 1px;
    }
}

.leaveTypeWrap {
    margin-bottom: 10px;

    .ant-radio-wrapper {
        margin-right: 10px;
    }
}

.leaveType {
    .ant-radio-wrapper {
        $grid: 3;
        width: calc(100% / #{$grid} - 10px);
        margin-right: calc(10px * #{$grid} / 2);
        margin-bottom: $space4;

        &:nth-child(3n + 3) {
            margin-right: 0;
        }
    }

    .ant-radio + span {
        width: calc(100% - (#{$space16} + #{$space8}));
        margin-left: $space8;
        padding: 0;
    }
}

.leaveSelectName {
    .ant-select-auto-complete {
        width: 100%;
    }

    .ant-select-disabled.ant-select {
        .ant-select-selector {
            color: $black;
        }
    }
}

.detail-tip {
    min-width: 550px;
}

.dayoffLists {
    @include Table;

    .tagsWrap {
        font-size: 13px;
    }

    .col-projectName {
        max-width: 240px;
    }

    [class*="btn-"] {
        color: $blue;
        padding: $space4 0;
        cursor: pointer;

        .anticon {
            display: inline-block;
        }
    }

    .btn-update {
        margin-right: $space16;
    }

    .col-leaveHour {
    }
}
