@import '../utils/variables';

$timelineLabel: 140px;

@mixin Timeline {
    .ant-timeline-item-label {
        width: 100%;
        max-width: $timelineLabel;
    }

    .ant-timeline-item-tail,
    .ant-timeline-item-head {
        @include positionLeft($timelineLabel);
    }

    &.ant-timeline-item-left {
        .ant-timeline-item-content {
            width: calc(100% - #{$timelineLabel} - 10px - #{$space30});
            @include positionLeft($timelineLabel);
        }
    }
}

@mixin TimelineItem {
    .ant-timeline-item-content {
        margin-left: $space30;
    }

    .timeline-create-info {
        letter-spacing: 1px;
        color: $grayDark;

        > * {
            font-size: $fontSize14;

            &:not(.btn-delete) {
                font-style: italic;
            }
        }

        .anticon {
            margin-right: $space8;
        }
    }

    .creator {
        text-decoration: underline;
    }

    .large-text {
        font-size: 40px;
    }

    .timeline-notes {
        font-size: 15px;
        background-color: $grayLight;
        margin-top: $space12;
        padding: 10px $space12;

        * {
            font-size: 15px;
            color: $blackLight;
        }

        p {
            margin-bottom: 0;
        }

        h4 {
            font-size: $fontSize16;
            font-weight: bold;
        }
    }

    .today {
        font-size: $fontSize18;
        color: $red;
    }
}

.timelineWrap {
    &.ant-timeline-label {
        .timelineItem {
            @include Timeline;
            @include TimelineItem;
            .ant-timeline-item-label {
                text-align: left;
            }
        }
    }

    .ant-timeline-item-head {
        &.ant-timeline-item-head-custom {
            @include positionLeft($timelineLabel);
        }
    }

    .timeline-content {
        letter-spacing: 1px;
        margin: 10px 0 $space16;
        padding-left: 22px;
    }

    .ant-timeline-item-last {
        .ant-timeline-item-content {
            display: none;
        }
    }

    .dot {
        $dot: 18px;
        width: $dot;
        height: $dot;
        border: 1px solid $blue;
        border-radius: 50%;
        display: inline-block;

        &:after {
            content: '';
            background-color: $blue;
            border-radius: 50%;
            display: inline-block;
            vertical-align: 1px;
            padding: 5px;
        }
    }

    .btn-delete {
        float: right;
        margin-top: -#{$space4};
        margin-right: -10px;
        padding: $space4 10px;
        cursor: pointer;

        &:hover,
        &:focus {
            margin-right: -10px;

            * {
                color: $black;
            }
        }

        * {
            font-weight: normal;
        }

        i {
            margin-right: $space4;
        }
    }
}
