@import '../utils/variables';

.advanced_search_wrap {
    *:not(.title) {
        font-size: 15px;
    }
}

.advanced-search-title {
    margin-bottom: $space;

    &:not(.clear) {
        font-size: $fontSize20;
    }

    .fa-filter {
        font-size: 18px;
        vertical-align: -1px;
        margin-right: $space4;
    }

    .clear {
        font-size: $fontSize14;
        color: $blue;
        float: right;
        margin-top: $space4;
        padding: 0 0 0 $space4;
        cursor: pointer;

        .anticon {
            margin-right: 2px;
        }
    }
}

.advanced-search-form {
    > * {
        margin-bottom: $space;
    }

    .search-item {
        display: block;

        .title {
            margin-bottom: $space4;
        }

        .input {
            width: 100%;
            height: 34px;
            background-color: $white;
            border: 1px solid $border;
            border-radius: 2px;
            padding: $space4 $space12;
            transition: all .3s ease-in-out;

            input {
                width: 100%;
                height: 100%;
            }
        }
    }

    .btn-submit {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
    }
}
