@import '../../utils/variables';

$authority: 40%;
$tabSpace: 120px;

.profileWrap {
    height: 100%;

    .tagsWrap {
        margin: 0;
        padding: 2px $space4;
    }
}

.profile-header {
    letter-spacing: 1px;
    background-color: $tableHead;
    padding: $space60 $tabSpace $space40;

    > * {
        @include v-align;
    }

    .left {
        text-align: left;
        margin-right: $space80;
        position: relative;

        .tagsWrap {
            position: absolute;
            right: -#{$space4};
            top: $space24;
        }
    }

    .right {
        > * {
            &:not(.title) {
                line-height: 2;
                margin-bottom: 0;
            }
        }
    }

    .thumbnail {
        $avatar: 200px;
        width: $avatar;
        height: $avatar;
        text-align: center;
        color: $black;
        background-color: $gray;
        border-radius: 50%;
        @include v-align;
        @include alignBox;

        span {
            margin-bottom: 0;
            @include v-align;
        }
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    svg[data-icon] {
        font-size: $fontSize18;
        vertical-align: middle;
        margin-right: $space8;
    }
}

.uploadPhoto {
    margin-top: 10px;
}

.cellphone {
    > * {
        @include v-align;
    }
}

.profile-content {
    margin-top: -50px;

    h2.title {
        font-size: 22px;
        font-weight: bold;
        text-align: left;
        margin-bottom: $space30;
    }
}

.profileTab {
    .ant-tabs-nav {
        padding-right: $tabSpace;
    }

    .ant-tabs-nav-wrap {
        justify-content: flex-end;
    }

    &.tabWrap {
        .ant-tabs-content-holder {
            padding: $space80 $tabSpace;
        }
    }
}

.personal {
    display: flex;

    .cell {
        flex: 1;
    }

    .cell-basic {
        flex: 0 0 56%;
        border-right: 2px solid $grayLight;
        margin-right: $space80;
        padding-right: $space80;
        position: relative;

        &:before,
        &:after {
            @include circle;
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }
    }

    .cell-authority {
        text-align: center;

        > div {
            display: inline-block;
        }

        .edit-authority-link {
            font-size: 15px;
            font-weight: normal;
            text-decoration: underline;
            float: right;
            margin-top: calc((34px - 25px) / 2);
        }

        .authority {
            text-align: left;
        }

        .authWrap {
            line-height: 1.6;
            margin-bottom: 10px;

            > span {
                min-width: 200px;
            }
        }
    }

    .copy-text {
        padding: $space4;
        cursor: pointer;
    }
}

.profile-on-top {
    position: relative;
    margin-bottom: $space40;

    h2.title {
        display: inline-block;
        margin-bottom: 0;
    }

    .titleBtn {
        float: right;
        margin-right: 0;

        &:hover,
        &:focus {
            margin-right: 0;
        }
    }
}

.row-item {
    @include flexAlign;

    .label {
        flex: 0 0 24%;
        margin-right: 10px;

        & + span {
            letter-spacing: 1px;
        }
    }

    .btn-groupUpdate {
        margin-left: 10px;
        padding: 4px 10px;

        span {
            font-size: 15px;
        }
    }
}

// 薪資
.salary {
    max-width: 50%;
    line-height: 1.8;
    margin: auto;
}

.salaryTimeline.ant-timeline-label {
    .timeline-content {
        i {
            margin-left: 6px;
        }
    }
}

// 組別異動記錄
.gropuTimeline-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
}

.groupTimeline.timelineWrap {
    *:not(.dot) {
        font-size: 15px;
    }

    svg[data-icon] {
        margin: 0 4px;
    }

    .timeline-content {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    &.ant-timeline-label .timelineItem {
        .timeline-notes {
            line-height: 1.2;
            margin-top: 0;
            padding: 10px;

            * {
                font-size: 15px;
            }

            h4 {
                margin-bottom: 4px;
            }
        }
    }

    .ant-timeline-item {
        padding-bottom: 10px;
    }
}

.groupHistoryForm {
    @include flexAlign;
    margin: 10px -4px 30px;

    > * {
        margin: 0 4px;
    }

    .filter-opts {
        flex: 1;

        > * {
            display: block;
        }
    }

    .actions button {
        &[type="submit"] {
            margin-left: 8px;
        }

        span {
            font-size: 15px;
        }
    }
}
