@import '../../utils/variables';

@mixin resetDateDateActive {
    .date {
        color: $black;
        background-color: transparent !important;
    }
}

@mixin resetCalendar {
    $cell: calc(200px / 7);

    &.ant-picker-calendar {
        margin-top: 0;

        * {
            font-size: 13px;
        }

        .ant-picker-calendar-header {
            display: none;
        }

        .ant-picker-panel .ant-picker-body th {
            width: $cell;
            height: $cell;
            padding: 0;
        }

        .ant-picker-cell {
            min-width: auto;
            border: 0;
            cursor: default;

            &:hover {
                @include resetDateDateActive;
            }

            &.ant-picker-cell-selected:not(.ant-picker-cell-today) {
                @include resetDateDateActive;
            }
        }
    }

    .dates {
        width: $cell;
        height: $cell;
        min-height: auto;
        padding: 0;
        background-color: #5CFFF4;
        @include alignBox;

        .date {
            margin-bottom: 0;
        }

        .name {
            display: none;
        }
    }

    .ant-picker-cell-disabled {
        .dates {
            background-color: transparent;
        }
    }

    .isHoliday {
        .date {
            color: $red;
        }
    }

    .ant-picker-cell-in-view:first-child,
    .ant-picker-cell-in-view:last-child,
    .ant-picker-cell-in-view:first-child,
    .ant-picker-cell-in-view:last-child {
        .date {
            color: $red;
        }
    }

    .ant-picker-cell-in-view [data-holiday="0"] .date {
        color: $black;
    }
}

.lazyCalendar {
    margin-bottom: $space;
    @include resetCalendar;
}
