@import '../utils/variables';

.pmb-tables {
    .ant-table {
        background-color: transparent;
    }

    th.ant-table-cell {
        font-weight: bold;
    }

    .ant-table-title {
        display: none;
        margin: $space 0;
        padding: 0;
    }

    .ant-table-row {
        background-color: $white;
        cursor: default;

        &:hover {
            background-color: $tableHead;

            .ant-table-cell {
                background-color: transparent;
            }
        }
    }

    .ant-table-cell {
        padding: $space8 $space12;
        position: relative;
    }

    .ant-table-empty {
        .ant-table-cell:not(th) {
            padding: 0;
        }
    }

    .col-creator {
        a {
            display: inline-block;
        }
    }

    .ant-table-column-sorters {
        padding: $space8 $space12;
    }
}

.no-data {
    .ant-table-tbody > tr > td {
        border-bottom: 0;
    }
}

.active {
    background-color: $tableHead;

    .ant-table-cell {
        &:after {
            content: '';
            display: block;
            width: 4px;
            height: 100%;
            background-color: $blue;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

// Table filter
.ant-table-filter-dropdown {
    .ant-btn span {
        line-height: 1.4;
        font-size: 15px;
    }
}

.pagination {
    &:first-child {
        margin-top: 0;
        margin-bottom: 10px;
    }

    & > [class*="ant-pagination-"] {
        margin-right: 10px;
    }

    .ant-pagination-next {
        margin-right: 0;
    }

    .ant-pagination-options {
        display: none;
    }

    .ant-pagination-total-text {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        margin-right: 0 !important;
    }

    i {
        font-size: $fontSize14;

        &.fa-chevron-left {
            margin-right: $space8;
        }

        &.fa-chevron-right {
            margin-left: $space8;
        }
    }

    .ant-pagination-item {
        @include resetPaginationBtns;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        @include paginationItemHeight;
    }

    .ant-pagination-item-active {
        @include paginationButtonLayout;
        @include resetPaginationBtns;

        a {
            color: $green;
            @include paginationButtonLayout;
        }
    }

    .ant-btn {
        @include resetPaginationBtns;
    }
}
