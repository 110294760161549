@import '../utils/variables';

.collapseWrap {
    &.withTopSpace {
        margin-top: $space;
    }

    .ant-collapse {
        border: 0;
        border-radius: 0;
    }

    .ant-collapse-content {
        border: 0;
    }

    .ant-collapse-item {
        border: 0;
        padding-bottom: $space8;

        .ant-collapse-header {
            padding: $space12 $space16 $space12 $space40;
        }
    }
}

.panelWrap {
    .ant-collapse-header {
        font-weight: bold;
        background-color: $gray;

        .anticon {
            svg {
                font-size: $fontSize14;
            }
        }
    }

    .ant-collapse-content-box {
        padding: 0;
    }
}

.small-text {
    font-size: 13px;
    font-weight: normal;
    color: $blackLight;
    margin-left: $space4;
}

.collWrap {
    padding: $space8 $space;
    @include flexAlign;

    &:not(:last-child) {
        border-bottom: 1px solid $border;
    }

    .coll-cell {
        flex: 1;
        padding: 0 $space8;
    }
}

// 新增按鈕
.btn-create {
    margin-top: -3px;
    padding: 2px $space8;
}
