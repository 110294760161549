@import '../../utils/variables';
@import './UploadFiles';
@import './ToDoList';

$contentTop: 32px;

// 計算上下方區塊: 總共筆數高度與間距 + 分頁高度與間距
@function calcSection ($betweenSpace: $space) {
    @return ($contentTop - #{$betweenSpace} * 2);
}

.projectDetailWrap {
    height: 100%;
    background-color: $white;
    padding: $space;

    .noData {
        padding-top: 120px;
    }

    .project-detail {
        .column-left {
            margin-right: $space40;
        }
    }
}

.project-header {
    .title {
        padding-right: 10px;

        h1 {
            font-size: 24px;
            font-weight: bold;
        }
    }

    .btn-actions {
        text-align: right;
    }

    .btn-update {
        margin-right: 0;
    }
}

.projectDetailSectionWrap {
    line-height: 1.6;
    margin-bottom: $space28;

    .label-title {
        margin-bottom: 10px;
        font-weight: bold;
        position: relative;
        cursor: default;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: $border1;
            position: absolute;
            left: 0;
            @include alignY;
        }

        span {
            background-color: $white;
            display: inline-block;
            padding-right: 6px;
            position: relative;
            z-index: 1;
        }

        .arrow-down {
            float: right;
            margin-top: 6px;
            padding: 0 0 0 $space4;
        }
    }

    .detail-content {
        line-height: 1.8;
        font-size: 15px;
        padding-left: $space16;
    }

    .tagsWrap {
        margin: 0;
    }
}

.payment-status {
    * {
        font-size: $fontSize14;
    }

    .tagsWrap.payment-status-tag {
        font-size: 13px;
        padding-top: 2px;
        padding-bottom: 2px;

        * {
            font-size: 13px;
        }
    }

    .anticon {
        vertical-align: -2px;
        margin-left: 0;
        margin-right: $space4;
    }

    .anticon-close {
        margin-right: 2px;
    }
}

.section-details {
    .col-right {
        margin-right: $space;
    }
}

.section-notes {
    min-height: 62px;
}

.detail-content-people {
    a {
        display: inline-block;
    }
}

// 新增小組預算按鈕
.btn-add {
    padding: 2px;
    cursor: pointer;
}

// 超時不列入計算，加刪除線
.extra-cost {
    text-decoration: line-through;
}

// 小組預算列表
.groupBudgetWrap {
    .group-budget-list {
        .warning-text {
            font-size: 13px;
            color: $blue;
            margin-left: $space4;
        }

        &.ant-collapse {
            border-bottom: 1px solid $grayDark;
            margin-bottom: $space4;
            padding-bottom: $space4;

            .ant-collapse-header {
                padding: 0 0 0 $space24;

                .ant-collapse-arrow {
                    left: 0;
                    top: $space4;
                }
            }

            .anticon {
                font-size: $fontSize14;
                padding: 2px;

                * {
                    font-size: $fontSize14;
                }
            }

            .ant-collapse-content {
                .ant-collapse-content-box {
                    padding: 0 0 0 $space24;

                    * {
                        font-size: 15px;
                        color: $grayDark;
                    }

                    .warning-text {
                        color: $red;
                    }
                }
            }
        }
    }

    .label {
        text-align: left;
    }

    .price {
        text-align: right;
    }

    .action {
        width: 56px;
        text-align: right;
        color: $grayDark;
        display: inline-block;
    }

    .anticon-delete {
        margin-left: 10px;
    }
}

// 小組預算總計
.group-budget-total {
    .ant-col {
        align-items: center;
    }

    .budget * {
        font-weight: bold;
    }

    .cost * {
        color: $red;
    }

    .balance {
        .price {
            font-size: 20px;
            color: $green;
        }

        .warning-text {
            color: $red;
        }
    }

    .price {
        text-align: right;
    }
}

.hasAuth {
    .group-budget-list,
    .group-budget-total {
        .price {
            padding-right: 56px;
        }
    }
}
