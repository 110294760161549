// Space
$space: 20px;
$space4: 4px;
$space8: 8px;
$space12: 12px;
$space16: 16px;
$space24: 24px;
$space28: 28px;
$space30: 30px;
$space36: 36px;
$space40: calc(#{$space} * 2);
$space60: calc(#{$space} * 3);
$space80: calc(#{$space} * 4);

// Font Size
$fontSize14: 0.9rem;
$fontSize16: 1rem;
$fontSize18: 1.125rem;
$fontSize20: 1.25rem;

// Color
$black: #333;
$blackLight: #5c5c5c;
$blackDark: #0c0c0c;
$white: #fff;
$blue: #2196f3;
$red: #ef5350;
$yellow: #efdc05;
$yellow: #f6bf26;
$gray: #e1e2e1;
$grayLight: #f5f5f5;
$grayDark: #b8b8b8;
$green: #4ca8aa;
$greenLight: #87d068;
$tableHead: #fafafa;
$tableBorder: #e8e8e8;
$border: #d9d9d9;
$border1: #f0f0f0;

// RWD 界線
$PhoneSM: 480;
$Phone: 768;
$Tablet: 1024;
$Laptop: 1200;
$Desktop: 1920;

// 規格
$height: 40px;
$navbarWidth: 260px;
$Header: 40px;

// 專案
$pagination: 32px; // Pagination 高度
$projectList: 1360px; // 專案列表高度

// Common Object
@mixin ButtonLayout ($color, $space: $space12) {
    color: $white;
    background-color: $color;
    border-color: $color;
    margin-right: $space;
}

@mixin resetButton ($color: $blue) {
    @include ButtonLayout($color);

    &:hover {
        opacity: .85;
        @include ButtonLayout($color);
    }

    &:focus,
    &:active,
    &.active {
        @include ButtonLayout($color);
    }
}

@mixin flexAlign {
    display: flex;
    align-items: center;
}

@mixin v-align {
    display: inline-block;
    vertical-align: middle;
}

@mixin alignX {
    left: 50%;
    transform: translateX(-50%);
}

@mixin alignY {
    top: 50%;
    transform: translateY(-50%);
}

@mixin alignBox {
    &:before {
        content: '';
        height: 100%;
        @include v-align;
    }
}

@mixin clearBox {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

@mixin modalBtns {
    .ant-btn {
        height: auto;
        line-height: initial;
        padding: $space8 $space40;
    }

    .ant-btn-primary {
        margin-left: $space;
    }
}

@mixin ellipsisText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin flexEllipsisText ($line: 1) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
    overflow: hidden;
}

@mixin calendarItem {
    &.type-full {
        padding: 0 $space8;

        .item {
            margin-bottom: 2px;
        }
    }

    &.type-simple {
        .item {
            font-size: 13px;
        }
    }

    * {
        font-size: $fontSize14;
    }

    .item {
        cursor: pointer;
    }

    .username {
        font-size: 15px;

        &:before {
            $circle: 6px;
            content: '';
            width: $circle;
            height: $circle;
            background-color: $blue;
            border-radius: 50%;
            display: inline-block;
            vertical-align: 2px;
            margin-right: 6px;
            position: initial;
        }
    }
}

@function circlePosition ($val) {
    @return -(($val / 2) + 1);
}

@mixin positionLeft ($width) {
    left: calc(#{$width} + 10px);
}

@mixin circle {
    $circle: 12px;
    content: '';
    position: absolute;
    width: $circle;
    height: $circle;
    background-color: $grayLight;
    border-radius: 50%;
    right: circlePosition($circle);
}

@mixin paginationItemHeight {
    min-width: 38px;
    height: $pagination;
    line-height: $pagination - 2px;
}

@mixin paginationButtonLayout {
    border-color: $blackLight;
}

@mixin resetPaginationBtns {
    @include paginationItemHeight;

    &:hover,
    &:focus {
        @include paginationButtonLayout;

        &:not(.ant-pagination-item-active) {
            a,
            .ant-btn {
                color: $blackLight;
            }
        }
    }

    &.ant-pagination-item-active {
        border-color: $blue;

        a,
        .ant-btn {
            color: $blue;
        }
    }
}

@mixin btnCreate {
    .btn-create {
        color: $white;
        background-color: $blue;
        padding: $space16;
        border-radius: 50%;
        position: fixed;
        right: $space;
        bottom: $space;
        z-index: 2;
        box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, .3);

        svg {
            width: 30px;
            height: 30px;
        }
    }
}

@mixin slideToHide ($timer: 0.4) {
    opacity: 1;
    transition: all #{$timer}s ease-in-out;

    &.slideToHide {
        height: 0;
        min-height: 0;
        border: 0;
        margin-bottom: 0;
        padding: 0;
        opacity: 0;
    }
}

@mixin place {
    &.first-place .ant-progress-text {
        color: gold;
    }

    &.second-place .ant-progress-text {
        color: silver;
    }

    &.third-place .ant-progress-text {
        color: #b78670;
    }
}

@mixin Table {
    .ant-table-content {
        * {
            font-size: $fontSize14;
        }

        .ant-table-cell {
            height: auto;
            padding: $space4 $space12;
        }

        th {
            font-size: 15px;
        }
    }
}
