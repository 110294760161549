@import '../utils/variables';

.cardsWrap {
    .ant-card-head {
        min-height: auto;
        padding: 10px $space;
    }

    .ant-card-head-wrapper {
        > * {
            padding: 0;
        }
    }

    .ant-card-head-title {
        font-size: $fontSize18;
        font-weight: bold;
    }

    .ant-card-extra {
        a {
            font-size: 15px;
            padding: 2px 0;
        }
    }

    .ant-card-body {
        padding: $space;
    }
}
