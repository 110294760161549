@import '../../utils/variables';

/*
 * Reference: https://css-tricks.com/simple-css-row-column-highlighting/
 */

.costRatioWrap {
    .col-costRatio {
        > * {
            @include v-align;
        }

        .inputWrap {
            margin-right: $space12;

            input {
                max-width: 80px;
                font-size: 15px;
                border: 1px solid $border;
                border-radius: 2px;
                margin-right: $space4;
                padding: $space4 10px;
            }
        }
    }

    .btn-primary span {
        font-size: 15px;
    }
}

.costRatioTable {
    max-width: 40%;
    background-color: $white;
    text-align: center;
    cursor: default;

    .row-item {
        &:first-child {
            background-color: $tableHead;
        }

        &:not(:first-child) {
            &:hover {
                background-color: $tableHead;
            }

            .ant-col {
                border-top: 1px solid $tableBorder;

                &:not(:last-child) {
                    border-right: 1px solid $tableBorder;
                }
            }
        }
    }

    .ant-col {
        min-height: 45px;
        padding: 10px $space16;
    }
}
