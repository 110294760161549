@import '../../utils/variables';

.todayDayoffWrap {
    padding: 6px $space;
    cursor: default;
    @include flexAlign;

    &:hover {
        background-color: $tableHead;
    }

    .link {
        font-size: 15px;
        display: inline-block;

        &:before {
            display: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    > * {
        flex: 1;
    }

    .group-name {
        font-size: $fontSize14;
        color: $grayDark;
        flex: 1.5;
    }

    .leave-info {
        text-align: right;

        * {
            font-size: 15px;
        }
    }
}

.todayDayoffTooltip {
    * {
        font-size: $fontSize14;
    }
}
