@import '../utils/variables';

.authWrap {
    @include flexAlign;

    > * {
        flex: 1;
    }

    > span {
        min-width: 140px;
    }

    div {
        text-align: right;
    }
}
