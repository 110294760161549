@import '../../utils/variables';

@mixin Calendar ($h) {
    .calendarWrap.ant-picker-calendar {
        margin-top: -$h;

        .ant-picker-panel .ant-picker-body th {
            font-size: $fontSize14;
            padding: $space4;
        }

        .ant-picker-calendar-header {
            padding-right: $space16;
            margin-bottom: $space16;

            .ant-radio-group {
                display: none;
            }

            .ant-select-selector {
                height: $h;
                line-height: $h;
            }

            .ant-select-selection-item {
                font-size: 15px;
            }
        }
    }
}

@mixin Dates () {
    .dates {
        min-height: 125px;
        padding: $space4 0;

        .date {
            $circle: 1.6em;
            width: $circle;
            height: $circle;
            line-height: $circle;
            font-size: 12px;
            margin-bottom: $space4;
        }

        .name,
        .item {
            line-height: 1;
            font-size: 13px;
            padding: 2px $space8 3px;
        }

        .items {
            min-height: auto;
        }
    }
}

.my-work-logs {
    $h: 30px;
    position: relative;
    @include Calendar($h);
    @include Dates;

    .noticeBox.ant-alert.ant-alert-no-icon {
        height: $h;
        margin-left: $space16;
        padding: 0 $space12;

        .ant-alert-message {
            font-size: 15px;
        }
    }

    .calendarWrap.ant-picker-calendar .ant-picker-panel .ant-picker-body th {
        padding: 10px;
    }

    .dates {
        min-height: 154px;
    }
}

.detail-tip {
    min-width: 360px;
}

.myWorkLogLists {
    @include Table;

    .tagsWrap {
        font-size: 13px;
    }

    .col-projectName {
        max-width: 240px;
    }

    [class*="btn-"] {
        color: $blue;
        padding: $space4 0;
        cursor: pointer;

        .anticon {
            display: inline-block;
        }
    }

    .btn-update {
        margin-right: $space16;
    }
}
