@import '../../../utils/variables';

.main {
    .notice {
        margin: 20px 0 40px;
        padding: 20px 40px;

        li {
            list-style-type: disc;
        }
    }

    .btn-createDepartment {
        margin-bottom: 20px;
    }

    .btn-toggle {
        margin: 0 6px;
    }
}

.content-header {
    margin-bottom: 20px;
    @include flexAlign;

    .title {
        font-size: 22px;
        font-weight: bold;
        margin-right: 20px;
        margin-bottom: 0;
    }
}

.treeWrap {
    .ant-tree-treenode {
        width: 400px;
        height: 32px;
        align-items: center;
        padding-bottom: 0;

        &:hover {
            background-color: $border1;

            .ant-tree-switcher {
                background-color: $border1;
            }
        }
    }

    .ant-tree-node-content-wrapper {
        flex: 1;
        padding-right: 0;
        &:hover {
            cursor: default;
        }
    }

    .ant-tree-switcher:not(.ant-tree-switcher-noop) {
        align-self: center;
    }
}

.row-title {
    display: flex;
    align-items: center;

    .action {
        text-align: right;
        flex: 1;
    }

    .anticon {
        margin: 0 2px;
        padding: 4px 6px;
        cursor: pointer;
    }

    .text {
        > span {
            margin-left: 4px;
        }

        span {
            font-size: 14px;
        }
    }

    .btn-createGroup {
        color: $blue;
        text-decoration: underline;
        cursor: pointer;
    }
}
