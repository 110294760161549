@import '../utils/variables';

body {
    background-color: #f0f2f5;
}

.guestWrap {
    height: 400px;
    text-align: center;

    &::before {
        content: '';
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }

    .align {
        display: inline-block;
        vertical-align: middle;
    }

    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .btn-primary {
        height: auto;
        padding: 4px 60px;

        span {
            font-size: 18px;
        }
    }
}

.loginWrap {
    .btn-primary {
        width: 100%;
        height: auto;
        padding: 4px;
    }
}
