@import '../utils/variables';

.tabWrap {
    &.ant-tabs-card {
        .ant-tabs-nav {
            margin-bottom: 0;
        }

        .ant-tabs-nav-container {
            height: auto;
        }

        .ant-tabs-tab {
            min-width: 140px;
            text-align: center;
            color: $blackLight;
            background-color: $gray;
            border: 0;
            display: inline-block;
            margin-right: 0 !important;
            padding: $space12 10px;
            position: relative;
            box-shadow: 2px 0 8px 0px rgba(0, 0, 0, 0.2);

            &:not(.ant-tabs-tab-active) {
                &:hover {
                    background-color: $border;
                }
            }
        }

        .ant-tabs-tab-active {
            color: $black;
            background-color: $white;

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: $blue;
                top: 0;
                left: 0;
            }
        }
    }

    &.ant-tabs-editable-card {
        .ant-tabs-tab-btn {
            display: inline-block;
        }
    }

    .ant-tabs-bar {
        margin: 0;
    }

    .ant-tabs-content-holder {
        background-color: $white;
        padding: $space40;
    }
}
