@import "../../utils/variables";

.btn-createTask {
  &.btn-primary {
    min-width: 20%;
    margin: $space 0;
  }
}

.summary {
  letter-spacing: 0.5px;
  text-align: right;

  .budget {
    color: $green;
  }

  .cost {
    color: $red;
  }
}

.toDoListWrap {
  .btn-actions {
    text-align: right;

    > * {
      cursor: pointer;
    }
  }
}

// 代辦項目
.toDoList {
  border: 1px solid $border1;
  border-radius: 4px;
  cursor: default;

  &:not(:last-child) {
    margin-bottom: $space12;
  }
}

.toDoList-group-title {
  line-height: 1.6;
  font-weight: bold;
  border-bottom: 1px solid $border1;
  padding: 6px #{$space12 / 2} 6px $space12;

  .small-text {
    font-size: $fontSize14;
    letter-spacing: 0.5px;
    vertical-align: 1px;
  }

  .btn-actions {
    .anticon {
      margin-left: $space4;
      padding: $space4 $space8;
    }
  }
}

// 任務
.taskWrap {
  .empty-data {
    text-align: center;
    color: $grayDark;
    margin-bottom: 0;
    padding: $space12;
  }

  .btn-actions {
    > * {
      color: $blue;
      margin-left: $space4;
      padding: 0 6px;
      @include v-align;

      span {
        margin-right: $space4;
      }
    }
  }
}

.col-taskName {
  width: 480px;

  .small-text {
    color: $grayDark;
    margin-left: 0;
  }
}

.col-assignee {
  .ant-table-filter-column-title {
    display: flex;
    justify-content: flex-end;
    padding-right: 28px !important;
    .ant-table-column-sorters {
      min-width: 100px;
      justify-content: flex-end;
    }
  }
  .assignee {
    display: flex;
    justify-content: flex-end;
    padding-right: 36.8px;
  }
}

.searchTaskForm {
  padding: 12px 10px;

  .row {
    @include v-align;
    margin: 0;
  }

  .row-btns {
    .ant-btn {
      margin-left: 10px;
      margin-right: 0;
      padding: 6px 20px;
    }
  }
}

.col-action {
  .btn {
    color: $blue;
    margin-left: 6px;
    padding: 4px;
    cursor: pointer;
  }
}

.col-totalCost {
  text-align: right;
}

.timeline-link {
  margin-left: $space16;
  @include v-align;

  > * {
    display: inline-block;
  }

  .look-at-timeline {
    font-size: 17px;
    text-decoration: underline;
  }

  .anticon {
    font-size: 14px;
    color: $grayDark;
    vertical-align: super;
    margin-left: $space4;

    * {
      font-size: 14px;
    }
  }
}

.look-at-timeline-tooltip {
  .ant-tooltip-inner {
    font-size: $fontSize14;
  }
}

// 表單
.filter-search,
.filter-reset {
  font-size: $fontSize14;
  color: $blue;
  margin-left: $space4;
  cursor: pointer;
}

.filter-reset {
  float: right;
  margin-top: 2px;
}

.form-filter-assignee {
  .row-filter-assignee-item {
    margin-bottom: $space;

    p {
      margin-bottom: $space4;
    }
  }

  .ant-select-selection-placeholder {
    font-size: 15px;
  }

  .multiple-select {
    width: 100%;
  }
}

.form-task {
  .warning-text {
    font-size: $fontSize14;
    color: $red;
    margin-left: $space4;
  }

  .has-cost {
    select {
      cursor: not-allowed;

      &:hover {
        border-color: $border;
      }
    }
  }
}

.col-cost,
.col-extra-cost,
.col-total-hours {
  min-width: 85px;
}

.btn-menu-actions {
  margin: 0;
  padding: 0;

  .anticon {
    padding: $space4 $space8;
  }
}
