@import '../utils/variables';

.prompt-wrap {
    .data {
        font-weight: bold;
    }

    .ant-modal-confirm-body {
        > * {
            &:not(.ant-modal-confirm-content) {
                @include v-align;
            }
        }

        .ant-modal-confirm-content {
            font-size: $space16;
            margin: $space16 48px $space40;

            * {
                font-size: $space16;
            }
        }

        .anticon {
            float: none;

            svg {
                font-size: 1.4rem;
            }
        }

        .ant-modal-confirm-title {
            font-size: 1.4rem;
        }
    }

    .ant-modal-confirm-btns {
        text-align: center;
        float: none;
        @include modalBtns;
    }
}
