@import '../../utils/variables';

.myProjectHeader {
    margin-bottom: $space;

    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 0;
        @include v-align;
    }

    .btn-primary {
        float: right;
        margin-top: 2.5px;
        margin-right: 0;

        &:hover,
        &:focus {
            margin-right: 0;
        }
    }
}

.myProject-lists {
    .ant-table-row {
        cursor: pointer;
    }

    .ant-table-expanded-row {
        .ant-table-cell,
        .ant-table-cell:first-child {
            padding: 0;
        }
    }

    .toDoListWrap {
        max-width: 900px;
        padding: $space12 $space60;

        .item-wrap {
            padding-left: $space24;
            position: relative;

            &:before {
                $circle: 6px;
                content: '';
                width: $circle;
                height: $circle;
                border-radius: 50%;
                background-color: $green;
                position: absolute;
                left: $space12;
                top: (23px - 6px) / 2;
            }

            &:hover {
                background-color: $grayLight;
            }

            * {
                cursor: default;
            }

            .btn-actions {
                * {
                    color: $blue;
                    cursor: pointer;
                }

                .anticon {
                    margin-right: 2px;
                }
            }
        }
    }

    .col-hours {
        text-align: right;
    }
}
