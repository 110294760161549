@import '../../utils/variables';

.member {
    a {
        display: inline-block;
        padding: 10px $space12 10px 0
    }

    .authority {
        line-height: 2;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
    }

    .authWrap {
        max-width: 280px;
        flex: 0 0 calc(100% / 3);
        padding-right: $space60;

        div {
            flex: 0 0 30%;
        }
    }
}
